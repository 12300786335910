@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,500&display=swap');
body{
   padding: 0;
   margin: 0;
   font-family: 'Poppins', sans-serif;
   background: var(--bg);
   transition: all 3ms ease;
   color: var(--black);
   scroll-behavior: smooth;
 
}
.container{
    padding: 1.5rem;
    display: flex;
}
.logo{
    display: flex;
    align-items: center;
   flex: 1; 
   gap: 2rem ;
}
.logo>img{
    height: 4.5rem;
    width: 4.5rem;
}
.logo>span{
    font-weight: 600;
}
.right{
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}
.menu{
    display: flex;
    gap: 2rem;
    list-style: none;
}
.menu>li:hover{
    color: palegreen;
    cursor: pointer;
}
.search{
    width: 7rem;
    height: 30%;
    outline: none;
    border: none;
    padding: .5rem;
    border-radius: 10px;
}
.cart{
    width: 2rem;
    height: 2rem;
}
.bars{
    display: none;
}
@media screen and (max-width: 856px) {
    .search{
        display: none;
    }
    .right{
        gap: 1rem;
    }
}
@media screen and (max-width: 640px) {
    .right{
        position: absolute;
        right: 2rem;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.611);
        color: #000;
        border-radius: 5px;
        flex-direction: column;
        padding: 1rem;
    }
    .menu{
        flex-direction: column;
        margin-left: -2rem;
        display: none;
    }
    .bars{
        display: block;
    }
    .search{
    display: none;
    }
}