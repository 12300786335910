.virtual{
    margin: 6rem 0rem;
    padding: 2rem;
    display: flex;
    justify-content: space-around;
    
}
.left{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}
.left>:nth-child(1){
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    width: 20rem;

}
.left>:nth-child(2){
    font-size: 1.6rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;

}
.left>:nth-child(3){
    font-size: 1.6rem;
   font-weight: bold;

}
.left>img{
    position: relative;
    width: 8rem;
    height: 5rem;
    bottom: 2rem;
}
.wrapper{
    width: 20rem;
    height: 20rem;
    border-radius: 10px;
}
.virtual .right .wrapper{
    border-radius: 10px;
}
@media screen and (max-width: 856px) {
    .virtual{
        gap: 1rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        padding: 1rem;
        text-align: center;
    }
    .left>img{
        position: relative;
      width: 100%;
        height: 5rem;
        bottom: 2rem;
    }
}