.Testimonial{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
   
}
.wrapper{
    display: grid;
    align-items: flex-end;
    width: 100%;
    grid-template-columns: 1fr 2fr 1fr;
    margin-top: 4rem;
}
.bgpng{
    background-image: url("D:/skinprojecct/src/assets/bg.png");
   background-size: contain;
}
.wrapper>img{
    width: 20rem;
    justify-self: center;
}
.container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.container>:nth-child(1){
    display: block;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
}
.container>:nth-child(2){
    display: block;
    text-transform: uppercase;
    font-size: .8rem;
}
.wrapper>:nth-child(3){
    text-align: right ;
}
.reviews{
    font-size: 2rem;
    font-weight: 600;
    margin: 3rem 0;
}
.carousel{
    width: 100%;
}

.tcarousel{
    padding: 2rem;
}
.testimonial{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    border-radius: .6rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 0rem 1rem  3rem -50px #7d7d7d;
}
.testimonial>img{
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: -1.5rem;
    left: 45%;
    background-color: aquamarine;
    border-radius: 50%;
}
.testimonial>span:nth-of-type(1){
align-items: center;
font-size: .8rem;
letter-spacing: 1px;
margin-top: 2rem;
text-align: center;
}
.testimonial>hr{
    height: 1px;
    width: 80%;
    background: rgb(5, 248, 196);
    border: none;
}
.testimonial>span:nth-of-type(2){
    font-weight: 600;
}


@media screen and (max-width: 640px) {
    .wrapper{
        grid-template-columns: 1fr;
    }
    .wrapper>img{
        width: 80vw;
        margin: 2rem 0rem;
    }
    .wrapper>:nth-child(3), .wrapper>:nth-child(1){
        text-align: center;
    }
    
}